import React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import HeroConsulting from "../../images/hero-consulting.svg"
import PrimaryButton from "../../components/buttonprimary"
import { Helmet } from "react-helmet"

export default function Consulting() {
	 return (
		  <Layout>
			  <Helmet>
					<meta charSet="utf-8" />
					<title>JustPax Collective | Consulting Services</title>
					<link rel="canonical" href="http://justpaxcollective.com" />
				</Helmet>
				<section class="section--consulting">
					 <section class="wrapper">
						  <div className="section--headline" style={{marginBottom:`0`}}>
								<div>
									 <h1>Consulting Framework</h1>
									 <p>When it comes to personal and organizational growth, reality is your best friend. We work with you to establish where you are and where you’d like to be.</p>
								</div>
						  </div>
						  <div>
							  <img src={HeroConsulting} alt="The Consulting Framework" />
						  </div>
						  <div className="flex-container section--details">
								<div>
									 <h2>Awareness</h2>
									 <p>Every organization has strengths and blindspots. After we determine your organizational baseline, we work with you to craft actionable, measurable steps for DEI growth.</p>
									 
								</div>
								<div>
									 <h2>Advocacy</h2>
									 <p>Caring for justice develops an ethos others desire. We partner with you to identify high-impact opportunities and practices to sustain DEI efforts, drive growth, and make lasting change in your community.</p>
									
								</div>
								<div>
									 <h2>Action</h2>
									 <p>We all have a part to play to mobilize justice and peacemaking. Through data-driven and human centric steps, discover how your team can fulfill its mission, recruit and retain talent, and develop changemakers.</p>
									
								</div>
						  </div>
						  <div>
							  <PrimaryButton buttonLink="/contact-us/" buttonText="Schedule a free consult" />
						  </div>
							<div style={{marginTop:`6rem`}}>
									<h2>Leadership Coaching</h2>
									<p>Partnering with executives and leaders to develop organizational strategy and personal capacity for DEI.</p>
							</div>
					 </section>
				</section>
		  </Layout>
	 )
}